/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
// pages
//import Index from "views/Index.js";
//import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
/* import ScanPage from "views/examples/ScanPage";
//import ProfilePage from "views/examples/ProfilePage.js";
import LoginPage from "views/examples/LoginPage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import ForgotPassword from "views/examples/ForgotPassword.js";
import ResetPassword from "views/examples/ResetPassword.js";
import ValidateEmail from "views/examples/ValidateEmail.js"; */
import SupportPage from "views/examples/SupportPage.js";

// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={(props) => <LandingPage {...props} />} />
      <Route
        path="/support"
        render={(props) => <SupportPage {...props} />}
        exact
      />
      {/*<Route
        path="/nucleo-icons"
        render={props => <NucleoIcons {...props} />}
      />
      <Route
        path="/props"
        render={props => <Index {...props} />}
      />
      <Route
        path="/profile-page"
        render={props => <ProfilePage {...props} />}
      />*/}
      {/* <Route path="/login" render={(props) => <LoginPage {...props} />} exact />
      <Route path="/scan" render={(props) => <ScanPage {...props} />} exact />
      <Route
        path="/register"
        render={(props) => <RegisterPage {...props} />}
        exact
      />
      <Route
        path="/forgotPassword"
        render={(props) => <ForgotPassword {...props} />}
        exact
      />
      <Route
        path="/resetPassword"
        render={(props) => <ResetPassword {...props} />}
        exact
      />
      <Route
        path="/activate/:token"
        render={(props) => <ValidateEmail {...props} />}
        exact
      /> 
      <Route
        exact
        path="/redirect"
        component={() => {
          window.location.href = "https://www.bouncer.com.br";
          return null;
        }}
      />*/}
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
