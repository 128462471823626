/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="https://www.microsistemas.com.br"
            target="_blank"
            title="Microsistemas"
          >
            <img
              src={
                navbarColor === ""
                  ? require("../../assets/img/microBlack.png")
                  : require("../../assets/img/microWhite.png")
              }
              alt="Microsistemas"
              style={{
                width: "9rem",
                marginTop: "-0.8rem",
              }}
            />
          </NavbarBrand>
          <NavbarBrand data-placement="bottom" href="/index" title="Bouncer">
            <img
              src={
                navbarColor === ""
                  ? require("../../assets/img/bouncer_black.svg")
                  : require("../../assets/img/bouncer_white.svg")
              }
              alt="Bouncer"
              style={{
                width: "4rem",
                marginTop: "-0.8rem",
              }}
            />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            {/*   <NavItem>
              <NavLink data-placement="bottom" href="/scan" title="Scan">
                <i className="fa fa-camera"></i>
                Ler Qr Code
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://use.bouncer.com.br/login"
                title="Entrar"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fa fa-sign-in"></i>
                Entrar
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://use.bouncer.com.br/register"
                title="Cadastre-se"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-user-plus" />
                Cadastre-se
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
