import React from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";

import "../../assets/css/main.css";

function SupportPage() {
  return (
    <>
      <ExamplesNavbar />
      <div
        className="page-header"
        style={{
          backgroundImage: "url(" + require("assets/img/login-image.jpg") + ")",
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="16">
              <Card className="card-register ml-auto mr-auto">
                <h2>A segurança e o gerenciamento do seu ambiente!</h2>
                <p
                  style={{ fontSize: "1rem", marginTop: 10, fontWeight: "400" }}
                >
                  O sistema de acesso Bouncer alia a segurança dos grandes
                  bancos com a praticidade de acesso e auditoria dos sistemas
                  remotos mais modernos.
                </p>{" "}
                <br></br>
                <h6>Utilizando o sistema:</h6>
                <ol>
                  <li>
                    Baixe o App Bouncer pelas lojas do seu dispositivo, Google
                    Play e Apple Store; Aponte o leitor do App para o Qr code do
                    local que deseja acessar. Certifique-se de que tenha
                    permissão para acessar esse ambiente;{" "}
                  </li>
                  <li>
                    Para dar acesso a convidados, utilize a aba à direita do
                    App, selecionando a unidade desejada e o email do convidado
                    cadastrado no App Bouncer dele.
                  </li>
                </ol>
                <br></br>
                <h6>Adquirindo um Bouncer:</h6>
                <ol>
                  <li>
                    Aponte o próprio leitor do App para o Qr code de ativação;
                  </li>
                  <li>
                    Agora você é um administrador do local e pode controlar as
                    permissões de acesso, ver históricos e configurar outras
                    funções diretamente pelo Painel de controle Online no link:{" "}
                    <a
                      href="https://bouncer.com.br/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textTransform: "lowercase",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      https://bouncer.com.br/login;
                    </a>
                  </li>
                  <li>
                    O Bouncer deve ser instalado conforme a fechadura utilizada
                    na porta, portão ou equipamento a ser acionado, e conectado
                    a rede wifi de acesso a internet. Caso necessite de ajuda,
                    contacte um dos nossos profissionais especializados pelos
                    canais abaixo.
                  </li>
                </ol>
                <p style={{ fontWeight: "400" }}>
                  Para dúvidas, questionamentos ou sugestões, acesse{" "}
                  <a
                    href="https://microsistemas.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textTransform: "lowercase",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    https://microsistemas.com.br
                  </a>{" "}
                  ou entre em contato conosco via email{" "}
                  <a
                    href="mailto:service@microsistemas.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textTransform: "lowercase",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    service@microsistemas.com.br
                  </a>{" "}
                  ou
                  <a
                    href="https://wa.me/message/E4EV63KYSO3ZP1"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {" "}
                    WhastApp: +55(41) 98848-5382
                  </a>
                  .
                </p>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="footer register-footer text-center">
          <h6>© {new Date().getFullYear()}, Bouncer</h6>
        </div>
      </div>
    </>
  );
}

export default SupportPage;
