/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <LandingPageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">O Bouncer</h2>
                <h5 className="description">
                  Bouncer é um produto inovador no mercado, que reúne
                  tecnologias de IoT com o poder da computação em nuvem.
                </h5>
                <br />
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Uma ideia</h4>
                    <p>
                      Controlar de maneira eficiente o acesso a lugares agora é
                      realidade.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Estatísticas</h4>
                    <p>
                      Obtenha de maneira prática todas as informações em um só
                      lugar.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Facilidade</h4>
                    <p>
                      Praticidade para todos os usuários utilizando o serviço.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="title">Contato comercial</h2>
                <p style={{ color: "white", fontWeight: "600" }}>
                  <i className="fa fa-whatsapp" style={{ marginRight: 5 }} />
                  +55 (41) 98848-5382
                </p>
                <a
                  href="https://www.microsistemas.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../assets/img/microWhite.png")}
                    alt="Microsistemas"
                    style={{
                      width: "9rem",
                      marginTop: "0.8rem",
                      marginRight: "0.5rem",
                    }}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default LandingPage;
